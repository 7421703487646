<template>
  <div class="branch-form">
    <v-form class="form" ref="form">
      <v-row>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="4">
          <label class="label-input">{{ $t("overtime_money.type") }}</label>
          <div class="ml-5">
            <v-radio-group v-model="type" class="lfont">
              <v-radio :label="$t('overtime_money.pay_cash')" value="cash"></v-radio>
              <v-radio :label="$t('overtime_money.pay_holiday')" value="holiday"></v-radio>
            </v-radio-group>
            <div>
              <label class="label-input">{{ $t("overtime_money.start_time") }}</label>
              <date-picker class="time--picker" v-model="start_time" style="width: 100%" type="time"></date-picker>
            </div>
            <div>
              <label class="label-input">{{ $t("overtime_money.end_time") }}</label>
              <date-picker class="time--picker" v-model="end_time" style="width: 100%" type="time"></date-picker>
            </div>
            <div>
              <label class="label-input">{{ $t("overtime_money.rate") }}</label>
              <v-text-field outlined dense v-model="rate"></v-text-field>
            </div>
            <v-btn class="btn-save-change" @click="saveChange()" style="width: 100% !important;">
              {{ $t("overtime_money.save") }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <label class="label-input">{{ $t("overtime_money.holiday") }}</label>
          <div class="ml-5">
            <div v-for="(item, i) in company_holiday" :key="i">
              <v-checkbox v-model="rest_day_type"
                :label="item.name != 'Normal' ? `${item.name} (${item.value})` : $t('overtime_money.normal')"
                color="primary" :value="item.id" hide-details class="lfont" style="margin: 10px 0 !important;"></v-checkbox>
            </div>

          </div>
        </v-col>
        <!-- <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="4"> -->
        <!-- <label class="label-input">{{ $t("overtime_money.start_time") }}</label>
          <date-picker class="time--picker" v-model="start_time" style="width: 100%" type="time"></date-picker> -->
        <!-- </v-col>
        <v-col cols="12" md="4"> -->
        <!-- <label class="label-input">{{ $t("overtime_money.end_time") }}</label>
          <date-picker class="time--picker" v-model="end_time" style="width: 100%" type="time"></date-picker> -->
        <!-- </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8"> -->
        <!-- <label class="label-input">{{ $t("overtime_money.rate") }}</label>
          <v-text-field outlined dense v-model="rate"></v-text-field> -->
        <!-- </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="8">
          <v-btn class="btn-save-change" @click="saveChange()">
            {{ $t("overtime_money.save") }}
          </v-btn>
        </v-col> -->
      </v-row>
    </v-form>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import Loading from "@/components/Loading";

export default {
  components: { Loading },
  data() {
    return {
      listCalculate: ["one", "all"],
      isLoading: false,
      qty: 1,
      rate: "",
      type: "cash",
      calculate: "one",
      start_time: "",
      end_time: "",
      rest_day_type: [],
      server_errors: {
        qty: "",
        rate: "",
        type: "",
        calculate: "",
      },
      company_holiday: []
    };
  },
  methods: {
    saveChange() {
      this.isLoading = true;
      const items = {
        qty: this.qty,
        rate: this.rate,
        type: this.type,
        calculate: this.calculate,
        rest_day_type: this.rest_day_type,
        start_time: moment(this.start_time).format("HH:mm:ss"),
        end_time: moment(this.end_time).format("HH:mm:ss"),
      };
      this.$axios
        .post(`company/ot/setting`, items)
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$router
              .push({
                name: "setting_ot.index",
              })
              .catch(() => { });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    fetch_holiday() {
      this.$axios
        .get(`company/ot/setting/holiday/list`)
        .then((res) => {
          if (res.status === 200) {
            this.company_holiday = res.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    }
  },

  created() {
    this.fetch_holiday();
  },
};
</script>

<style lang="scss" scoped>
.branch-form {
  // background-color: red;
  width: 100%;
  // height: 600px;
  display: flex;
  justify-content: center;
  // align-items: center;

  .form {
    padding: 50px 0;
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
